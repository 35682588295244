const Swal = require('sweet-alert-latest');
export const showSwalToast = (body: any) => {
    Swal.mixin({
        toast: true,
        timer: 9000,
        timerProgressBar: true,
        position: 'top-end',
        onOpen: (toast: any) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
        ...body,
    }).fire();
};
