import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
// import '@coreui/icons/css/coreui-icons.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
// Import Main styles for this application
import '../scss/style.scss';

import { topLevelRoutes } from './routes';
import { Spinner } from '../components';

const App = () => (
    <Suspense fallback={<Spinner type="global" />}>
        <Switch>
            {topLevelRoutes.map((route, id) => {
                const { Component, render, exact, path, name } = route;
                return Component || render ? (
                    <Route
                        key={id}
                        exact={exact}
                        path={path}
                        name={name}
                        component={Component}
                        render={render}
                    />
                ) : null;
            })}
        </Switch>
    </Suspense>
);

export default App;
