// Conversion Funnel
export const GET_FUNNEL_DATA_SUMMARY = 'GET_FUNNEL_DATA_SUMMARY';
export const GET_FUNNEL_DATA_SUMMARY_SUCCESS =
    'GET_FUNNEL_DATA_SUMMARY_SUCCESS';
export const GET_FUNNEL_DATA_SUMMARY_FAILURE =
    'GET_FUNNEL_DATA_SUMMARY_FAILURE';
export const DOWNLOAD_FUNNEL_DATA_SUMMARY = 'DOWNLOAD_FUNNEL_DATA_SUMMARY';
export const DOWNLOAD_FUNNEL_DATA_SUMMARY_SUCCESS =
    'DOWNLOAD_FUNNEL_DATA_SUMMARY_SUCCESS';
export const DOWNLOAD_FUNNEL_DATA_SUMMARY_FAILURE =
    'DOWNLOAD_FUNNEL_DATA_SUMMARY_FAILURE';
// User
export const USER_AUTHENTICATION_SUCCESS = 'USER_AUTHENTICATION_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const AUTH_GENCODE_ERROR = 'AUTH_GENCODE_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const AUTH_ERROR_FORGOT = 'AUTH_ERROR_FORGOT';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const HIDE_MESSAGES = 'HIDE_MESSAGES';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const RESET_LOGIN_FORM = 'RESET_LOGIN_FORM';
export const USER_PROFILE_DATA_REQUEST = 'USER_PROFILE_DATA_REQUEST';
export const USER_PROFILE_DATA_SUCCESS = 'USER_PROFILE_DATA_SUCCESS';
export const USER_PROFILE_CHANGE_PASSWORD_REQUEST =
    'USER_PROFILE_CHANGE_PASSWORD_REQUEST';
export const USER_PROFILE_CHANGE_PASSWORD_SUCCESS =
    'USER_PROFILE_CHANGE_PASSWORD_SUCCESS';
export const USER_PROFILE_CHANGE_PASSWORD_FAILURE =
    'USER_PROFILE_CHANGE_PASSWORD_FAILURE';
export const USER_PROFILE_SETTINGS_UPDATE_REQUEST =
    'USER_PROFILE_SETTINGS_UPDATE_REQUEST';
export const USER_PROFILE_SETTINGS_UPDATE_SUCCESS =
    'USER_PROFILE_SETTINGS_UPDATE_SUCCESS';

// Sidebar
export const GET_SIDEBAR_DATA = 'GET_SIDEBAR_DATA';
export const SESSIONID_EXPIRED = 'SESSIONID_EXPIRED';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Header
export const GET_USER_CLIENTS_LIST_REQUEST = 'GET_USER_CLIENTS_LIST_REQUEST';
export const GET_USER_CLIENTS_LIST_SUCCESS = 'GET_USER_CLIENTS_LIST_SUCCESS';
export const SELECT_ACTIVE_CLIENT = 'SELECT_ACTIVE_CLIENT';
export const SELECT_ACTIVE_RELOAD = 'SELECT_ACTIVE_RELOAD';
// Dashboard

// Common actions
export const COMMON_CONSTANTS = 'COMMON_CONSTANTS';
export const COMMON_COMMENTS = 'COMMON_COMMENTS';
export const CLIENT_LIST_ALL = 'GET_USER_CLIENT_LIST_ALL';
export const USER_ROLES = 'GET_USER_ROLES';
export const COUNTRIES = 'COUNTRIES';
export const TIMEZONES = 'TIMEZONES';
export const TIME_OFFSETS = 'TIME_OFFSETS';
export const LEGAL_ENTITIES = 'LEGAL_ENTITIES';
export const CHANGE_PAGE_SIZE_REQUEST = 'CHANGE_PAGE_SIZE_REQUEST';
export const CHANGE_PAGE_SIZE_REQUEST_COMPLETE = 'CHANGE_PAGE_SIZE_REQUEST_COMPLETE';
export const REFETCH_DATA = 'REFETCH_DATA';
export const REFETCH_DATA_REQUEST = 'REFETCH_DATA_REQUEST';
export const MENU_ITEMS = 'MENU_ITEMS';
export const CLIENT_LIST_WITH_RISK_LEVEL = 'CLIENT_LIST_WITH_RISK_LEVEL'

// =================== Pages =================== //


// Tiered Pricing Calculator
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';

// ScheduledFX
export const SCHEDULED_FX_DATA_REQUEST = 'SCHEDULED_FX_DATA_REQUEST';
export const SCHEDULED_FX_DATA_SUCCESS = 'SCHEDULED_FX_DATA_SUCCESS';
export const SCHEDULED_FX_DATA_FAILURE = 'SCHEDULED_FX_DATA_FAILURE';
export const SCHEDULED_FX_APPROVE_REQUEST = 'SCHEDULED_FX_APPROVE_REQUEST';
export const SCHEDULED_FX_APPROVE_SUCCESS = 'SCHEDULED_FX_APPROVE_SUCCESS';
export const SCHEDULED_FX_APPROVE_FAILURE = 'SCHEDULED_FX_APPROVE_FAILURE';

// Regulatory blacklist
export const REGULATORY_BLACKLIST = 'REGULATORY_BLACKLIST';
export const REGULATORY_BLACKLIST_FILES = 'REGULATORY_BLACKLIST_FILES';
export const REGULATORY_BLACKLIST_UPDATED = 'REGULATORY_BLACKLIST_UPDATED';

// Stats
export const STATS = 'STATS';
// Compliance RFI
export const COMPLIANCE_RFI_DATA_REQUEST = 'COMPLIANCE_RFI_DATA_REQUEST';
export const COMPLIANCE_RFI_DATA_SUCCESS = 'COMPLIANCE_RFI_DATA_SUCCESS';
export const COMPLIANCE_RFI_DATA_FAILURE = 'COMPLIANCE_RFI_DATA_FAILURE';
export const COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST =
    'COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST';
export const COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS =
    'COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS';
export const COMPLIANCE_RFI_APPROVED_BY_LIST =
    'COMPLIANCE_RFI_APPROVED_BY_LIST';
export const COMPLIANCE_RFI_CHECKBOX = 'COMPLIANCE_RFI_CHECKBOX';
export const COMPLIANCE_RFI_REQUEST_DETAILS = 'COMPLIANCE_RFI_REQUEST_DETAILS';

export const COMPLIANCE_RFI_APPROVE_TRANSACTION_SUCCESS =
    'COMPLIANCE_RFI_APPROVE_TRANSACTION_SUCCESS';
export const COMPLIANCE_RFI_REJECT_TRANSACTION_SUCCESS =
    'COMPLIANCE_RFI_REJECT_TRANSACTION_SUCCESS';
export const COMPLIANCE_RFI_RESPONSE_APPROVAL_REQUEST =
    'COMPLIANCE_RFI_RESPONSE_APPROVAL_REQUEST';
export const COMPLIANCE_RFI_RESPONSE_APPROVAL_SUCCESS =
    'COMPLIANCE_RFI_RESPONSE_APPROVAL_SUCCESS';
export const COMPLIANCE_RFI_RESPONSE_APPROVAL_FAILURE =
    'COMPLIANCE_RFI_RESPONSE_APPROVAL_FAILURE';
export const COMPLIANCE_RFI_REJECT_TRANSACTION =
    'COMPLIANCE_RFI_REJECT_TRANSACTION';
export const COMPLIANCE_RFI_APPROVE_TRANSACTION =
    'COMPLIANCE_RFI_APPROVE_TRANSACTION';
export const COMPLIANCE_RFI_REQUEST_DETAILS_SUCCESS =
    'COMPLIANCE_RFI_REQUEST_DETAILS_SUCCESS';

// Compliance Alert

export const COMPLIANCE_ALERT_DATA_REQUEST = 'COMPLIANCE_ALERT_DATA_REQUEST';
export const COMPLIANCE_ALERT_DATA_SUCCESS = 'COMPLIANCE_ALERT_DATA_SUCCESS';
export const COMPLIANCE_ALERT_DATA_FAILURE = 'COMPLIANCE_ALERT_DATA_FAILURE';
export const COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_REQUEST =
    'COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_REQUEST';
export const COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_SUCCESS =
    'COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_SUCCESS';
export const COMPLIANCE_ALERT_UPDATED_BY_LIST =
    'COMPLIANCE_ALERT_UPDATED_BY_LIST';
export const COMPLIANCE_ALERT_CHECKBOX = 'COMPLIANCE_ALERT_CHECKBOX';
export const COMPLIANCE_ALERT_REQUEST_DETAILS =
    'COMPLIANCE_ALERT_REQUEST_DETAILS';

export const COMPLIANCE_ALERT_APPROVE_TRANSACTION_SUCCESS =
    'COMPLIANCE_ALERT_APPROVE_TRANSACTION_SUCCESS';
export const COMPLIANCE_ALERT_REJECT_TRANSACTION_SUCCESS =
    'COMPLIANCE_ALERT_REJECT_TRANSACTION_SUCCESS';

export const COMPLIANCE_ALERT_CHANGE_STATUS_REQUEST =
    'COMPLIANCE_ALERT_CHANGE_STATUS_REQUEST';
export const COMPLIANCE_ALERT_CHANGE_STATUS_SUCCESS =
    'COMPLIANCE_ALERT_CHANGE_STATUS_SUCCESS';

export const COMPLIANCE_ALERT_RESPONSE_APPROVAL_FAILURE =
    'COMPLIANCE_ALERT_RESPONSE_APPROVAL_FAILURE';
export const COMPLIANCE_ALERT_REJECT_TRANSACTION =
    'COMPLIANCE_ALERT_REJECT_TRANSACTION';
export const COMPLIANCE_ALERT_APPROVE_TRANSACTION =
    'COMPLIANCE_ALERT_APPROVE_TRANSACTION';
export const COMPLIANCE_ALERT_REQUEST_DETAILS_SUCCESS =
    'COMPLIANCE_ALERT_REQUEST_DETAILS_SUCCESS';
export const COMPLIANCE_ALERT_CSV_DOWNLOAD_SUCCESS =
    'COMPLIANCE_ALERT_CSV_DOWNLOAD_SUCCESS';
export const COMPLIANCE_ALERT_CSV_DOWNLOAD_REQUEST =
    'COMPLIANCE_ALERT_CSV_DOWNLOAD_REQUEST';

// Compliance Scan Report
export const COMPLIANCE_SCAN_DATA_REQUEST = 'COMPLIANCE_SCAN_DATA_REQUEST';
export const COMPLIANCE_SCAN_DATA_SUCCESS = 'COMPLIANCE_SCAN_DATA_SUCCESS';
export const COMPLIANCE_SCAN_CSV_DOWNLOAD_REQUEST =
    'COMPLIANCE_SCAN_CSV_DOWNLOAD_REQUEST';
export const COMPLIANCE_SCAN_CSV_DOWNLOAD_SUCCESS =
    'COMPLIANCE_SCAN_CSV_DOWNLOAD_SUCCESS';
export const COMPLIANCE_SCAN_CSV_DOWNLOAD_FAILURE =
    'COMPLIANCE_SCAN_CSV_DOWNLOAD_FAILURE';
export const COMPLIANCE_RFI_CSV_DOWNLOAD_REQUEST =
    'COMPLIANCE_RFI_CSV_DOWNLOAD_REQUEST';
export const COMPLIANCE_RFI_CSV_DOWNLOAD_SUCCESS =
    'COMPLIANCE_RFI_CSV_DOWNLOAD_SUCCESS';
export const COMPLIANCE_RFI_CSV_DOWNLOAD_FAILURE =
    'COMPLIANCE_RFI_CSV_DOWNLOAD_FAILURE';

// AuditLogs
export const AUDIT_LOGS_ACTION = 'AUDIT_LOGS_ACTION';
export const API_ERROR = 'API_ERROR';


export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// Manual Payout
export const MANUAL_PAYOUT_DATA_REQUEST = 'MANUAL_PAYOUT_DATA_REQUEST';
export const MANUAL_PAYOUT_DATA_SUCCESS = 'MANUAL_PAYOUT_DATA_SUCCESS';
export const MANUAL_PAYOUT_DATA_FAILURE = 'MANUAL_PAYOUT_DATA_FAILURE';
export const MANUAL_PAYOUT_FILE_DATA_REQUEST =
    'MANUAL_PAYOUT_FILE_DATA_REQUEST';
export const MANUAL_PAYOUT_FILE_DATA_SUCCESS =
    'MANUAL_PAYOUT_FILE_DATA_SUCCESS';
export const MANUAL_PAYOUT_FILE_DATA_FAILURE =
    'MANUAL_PAYOUT_FILE_DATA_FAILURE';
export const MANUAL_PAYOUT_DOWNLOAD_REQUEST = 'MANUAL_PAYOUT_DOWNLOAD_REQUEST';
export const MANUAL_PAYOUT_DOWNLOAD_SUCCESS = 'MANUAL_PAYOUT_DOWNLOAD_SUCCESS';
export const MANUAL_PAYOUT_DOWNLOAD_FAILURE = 'MANUAL_PAYOUT_DOWNLOAD_FAILURE';
export const MANUAL_PAYOUT_REGENERATE_DOWNLOAD_REQUEST =
    'MANUAL_PAYOUT_REGENERATE_DOWNLOAD_REQUEST';
export const MANUAL_PAYOUT_REGENERATE_DOWNLOAD_SUCCESS =
    'MANUAL_PAYOUT_REGENERATE_DOWNLOAD_SUCCESS';
export const MANUAL_PAYOUT_REGENERATE_DOWNLOAD_FAILURE =
    'MANUAL_PAYOUT_REGENERATE_DOWNLOAD_FAILURE';
export const MANUAL_PAYOUT_FILTER_GENERATE_REQUEST =
    'MANUAL_PAYOUT_FILTER_GENERATE_REQUEST';
export const MANUAL_PAYOUT_BANKS_REQUEST = 'MANUAL_PAYOUT_BANKS_REQUEST';
export const CHANGE_HISTORY_PAGE_SIZE_REQUEST =
    'CHANGE_HISTORY_PAGE_SIZE_REQUEST';

// Malaysian Reports
export const MALAYSIAN_REPORT_CSV_DOWNLOAD_REQUEST =
    'MALAYSIAN_REPORT_CSV_DOWNLOAD_REQUEST';
export const MALAYSIAN_REPORT_CSV_DOWNLOAD_SUCCESS =
    'MALAYSIAN_REPORT_CSV_DOWNLOAD_SUCCESS';
export const MALAYSIAN_REPORT_CSV_DOWNLOAD_FAILURE =
    'MALAYSIAN_REPORT_CSV_DOWNLOAD_FAILURE';

// Finance Summary Report
export const FINANCE_SUMMARY_DATA_REQUEST = 'FINANCE_SUMMARY_DATA_REQUEST';
export const FINANCE_SUMMARY_DATA_SUCCESS = 'FINANCE_SUMMARY_DATA_SUCCESS';
export const FINANCE_SUMMARY_DATA_FAILURE = 'FINANCE_SUMMARY_DATA_FAILURE';
export const FINANCE_SUMMARY_CSV_DOWNLOAD_REQUEST =
    'FINANCE_SUMMARY_CSV_DOWNLOAD_REQUEST';
export const FINANCE_SUMMARY_CSV_DOWNLOAD_SUCCESS =
    'FINANCE_SUMMARY_CSV_DOWNLOAD_SUCCESS';
export const FINANCE_SUMMARY_CSV_DOWNLOAD_FAILURE =
    'FINANCE_SUMMARY_CSV_DOWNLOAD_FAILURE';
export const FINANCE_SUMMARY_FILTER_GENERATE_REQUEST =
    'FINANCE_SUMMARY_FILTER_GENERATE_REQUEST';

// Manual payout confirmation
export const MANUAL_PAYOUT_CONFIRMATION_BANKS_REQUEST =
    'MANUAL_PAYOUT_CONFIRMATION_BANKS_REQUEST';
export const MANUAL_PAYOUT_CONFIRMATION_ISO_RETURN_REQUEST =
    'MANUAL_PAYOUT_CONFIRMATION_ISO_RETURN_REQUEST';
export const MANUAL_PAYOUT_CONFIRMATION_DATA_REQUEST =
    'MANUAL_PAYOUT_CONFIRMATION_DATA_REQUEST';
export const MANUAL_PAYOUT_CONFIRMATION_DATA_SUCCESS =
    'MANUAL_PAYOUT_CONFIRMATION_DATA_SUCCESS';
export const MANUAL_PAYOUT_CONFIRMATION_DATA_FAILURE =
    'MANUAL_PAYOUT_CONFIRMATION_DATA_FAILURE';
export const MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_TYPE =
    'MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_TYPE';
export const MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_STATUS =
    'MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_STATUS';
export const MANUAL_PAYOUT_CONFIRMATION_SUBMIT_STATUS_REQUEST =
    'MANUAL_PAYOUT_CONFIRMATION_SUBMIT_STATUS_REQUEST';
export const MANUAL_PAYOUT_CONFIRMATION_SUBMIT_STATUS_SUCCESS =
    'MANUAL_PAYOUT_CONFIRMATION_SUBMIT_STATUS_SUCCESS';

// Locked Transactions
export const LOCKED_TRANSACTIONS_DATA_REQUEST =
    'LOCKED_TRANSACTIONS_DATA_REQUEST';
export const LOCKED_TRANSACTIONS_DATA_SUCCESS =
    'LOCKED_TRANSACTIONS_DATA_SUCCESS';
export const LOCKED_TRANSACTIONS_DATA_FAILURE =
    'LOCKED_TRANSACTIONS_DATA_FAILURE';
export const LOCKED_TRANSACTIONS_REMOVE_LOCK_REQUEST =
    'LOCKED_TRANSACTIONS_REMOVE_LOCK_REQUEST';
export const LOCKED_TRANSACTIONS_CHANGE_STATUS_REQUEST =
    'LOCKED_TRANSACTIONS_CHANGE_STATUS_REQUEST';

// Transaction History
export const TRANSACTION_HISTORY_DATA_REQUEST =
    'TRANSACTION_HISTORY_DATA_REQUEST';
export const TRANSACTION_HISTORY_DATA_SUCCESS =
    'TRANSACTION_HISTORY_DATA_SUCCESS';
export const TRANSACTION_HISTORY_DATA_FAILURE =
    'TRANSACTION_HISTORY_DATA_FAILURE';
export const TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST =
    'TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST';
export const TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS =
    'TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS';
export const TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE =
    'TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE';
export const TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST =
    'TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST';
export const TRANSACTION_HISTORY_NOTIFY_RECIPIENT_REQUEST =
    'TRANSACTION_HISTORY_NOTIFY_RECIPIENT_REQUEST';
export const TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST =
    'TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST';
export const TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS =
    'TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS';
export const TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE =
    'TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE';
export const TRANSACTION_HISTORY_CANCEL_TRANSACTION_REQUEST =
    'TRANSACTION_HISTORY_CANCEL_TRANSACTION_REQUEST';
export const TRANSACTION_HISTORY_CANCEL_TRANSACTION_SUCCESS =
    'TRANSACTION_HISTORY_CANCEL_TRANSACTION_SUCCESS';
export const TRANSACTION_HISTORY_CANCEL_TRANSACTION_FAILURE =
    'TRANSACTION_HISTORY_CANCEL_TRANSACTION_FAILURE';
export const TRANSACTION_HISTORY_POPRECALL_REQUEST = 'TRANSACTION_HISTORY_POPRECALL_REQUEST';
export const TRANSACTION_HISTORY_POPRECALL_RESPONSE = 'TRANSACTION_HISTORY_POPRECALL_RESPONSE';
export const TRANSACTION_HISTORY_CAAS_DETAILS_API = 'TRANSACTION_HISTORY_CAAS_DETAILS_API';

// Re Process Payments
export const GET_PAYMENT_GATEWAY_REQUEST = 'GET_PAYMENT_GATEWAY_REQUEST';
export const GET_PAYMENT_GATEWAY_SUCCESS = 'GET_PAYMENT_GATEWAY_SUCCESS';
export const GET_PAYMENT_GATEWAY_FAILURE = 'GET_PAYMENT_GATEWAY_FAILURE';
export const RE_PROCESS_PAYMENTS_DATA_REQUEST =
    'RE_PROCESS_PAYMENTS_DATA_REQUEST';
export const RE_PROCESS_PAYMENTS_DATA_SUCCESS =
    'RE_PROCESS_PAYMENTS_DATA_SUCCESS';
export const RE_PROCESS_PAYMENTS_DATA_FAILURE =
    'RE_PROCESS_PAYMENTS_DATA_FAILURE';
export const RE_PROCESS_PAYMENTS_SUBMIT_REQUEST =
    'RE_PROCESS_PAYMENTS_SUBMIT_REQUEST';
export const RE_PROCESS_PAYMENTS_SUBMIT_SUCCESS =
    'RE_PROCESS_PAYMENTS_SUBMIT_SUCCESS';
export const RE_PROCESS_PAYMENTS_FILTER_GENERATE_REQUEST =
    'RE_PROCESS_PAYMENTS_FILTER_GENERATE_REQUEST';
export const RE_PROCESS_PAYMENTS_STATUS_REQUEST =
    'RE_PROCESS_PAYMENTS_STATUS_REQUEST';

// Remitter / Beneficiary check
export const SAVE_RBCHECK_PARENT_STATE = 'SAVE_RBCHECK_PARENT_STATE';
export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHECK_SINGLE_REPORT_DATA_REQUEST =
    'CHECK_SINGLE_REPORT_DATA_REQUEST';
export const CHECK_SINGLE_REPORT_DATA_SUCCESS =
    'CHECK_SINGLE_REPORT_DATA_SUCCESS';
export const CHECK_REPORT_DATA_REQUEST = 'CHECK_REPORT_DATA_REQUEST';
export const CHECK_REPORT_DATA_SUCCESS = 'CHECK_REPORT_DATA_SUCCESS';
export const CHECK_REPORT_DATA_FAILURE = 'CHECK_REPORT_DATA_FAILURE';
export const GET_RFIDOCSDETAILS_REQUEST = 'GET_RFIDOCSDETAILS_REQUEST';
export const GET_RFIDOCSDETAILS_SUCCESS = 'GET_RFIDOCSDETAILS_SUCCESS';
export const GET_RFIDOCSDETAILS_FAILURE = 'GET_RFIDOCSDETAILS_FAILURE';
export const GET_TRANSACTION_HISTORY_REQUEST =
    'GET_TRANSACTION_HISTORY_REQUEST';
export const GET_TRANSACTION_HISTORY_SUCCESS =
    'GET_TRANSACTION_HISTORY_SUCCESS';
export const GET_TRANSACTION_HISTORY_FAILURE =
    'GET_TRANSACTION_HISTORY_FAILURE';
export const GET_COMPLIANCE_STATUS_HISTORY_REQUEST =
    'GET_COMPLIANCE_STATUS_HISTORY_REQUEST';
export const GET_COMPLIANCE_STATUS_HISTORY_SUCCESS =
    'GET_COMPLIANCE_STATUS_HISTORY_SUCCESS';
export const GET_COMPLIANCE_STATUS_HISTORY_FAILURE =
    'GET_COMPLIANCE_STATUS_HISTORY_FAILURE';
export const SET_CURRENT_COMPLIANCE_LIST_TYPE =
    'SET_CURRENT_COMPLIANCE_LIST_TYPE';

// Account Statement
export const ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT =
    'ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT';
export const ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_SUCCESS =
    'ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_SUCCESS';
export const ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_FAILURE =
    'ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_FAILURE';
export const ACCOUNT_STATEMENT_DATA_REQUEST = 'ACCOUNT_STATEMENT_DATA_REQUEST';
export const ACCOUNT_STATEMENT_DATA_SUCCESS = 'ACCOUNT_STATEMENT_DATA_SUCCESS';
export const ACCOUNT_STATEMENT_DATA_FAILURE = 'ACCOUNT_STATEMENT_DATA_FAILURE';
export const ACCOUNT_STATEMENT_FILTER_REQUEST =
    'ACCOUNT_STATEMENT_FILTER_REQUEST';
export const ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS =
    'ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS';
export const ACCOUNT_STATEMENT_DOWNLOAD_FAILURE =
    'ACCOUNT_STATEMENT_DOWNLOAD_FAILURE';
export const ACCOUNT_STATEMENT_DOWNLOAD_REQUEST =
    'ACCOUNT_STATEMENT_DOWNLOAD_REQUEST';
export const ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST =
    'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST';
export const ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS =
    'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS';
export const ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE =
    'ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE';

// GL Entry
export const GL_ENTRY_ACCOUNTS_LIST_REQUEST = 'GL_ENTRY_ACCOUNTS_LIST_REQUEST';
export const GL_ENTRY_ACCOUNTS_LIST_SUCCESS = 'GL_ENTRY_ACCOUNTS_LIST_SUCCESS';
export const GL_ENTRY_LEDGER_HISTORY_REQUEST =
    'GL_ENTRY_LEDGER_HISTORY_REQUEST';
export const GL_ENTRY_LEDGER_HISTORY_SUCCESS =
    'GL_ENTRY_LEDGER_HISTORY_SUCCESS';
export const GL_ENTRY_SAVE_NEW_LEDGER_REQUEST =
    'GL_ENTRY_SAVE_NEW_LEDGER_REQUEST';
export const GL_ENTRY_SAVE_NEW_LEDGER_SUCCESS =
    'GL_ENTRY_SAVE_NEW_LEDGER_SUCCESS';
export const GL_ENTRY_LEDGER_ACTION_REQUEST = 'GL_ENTRY_LEDGER_ACTION_REQUEST';
export const GL_ENTRY_CSV_DOWNLOAD_REQUEST = 'GL_ENTRY_CSV_DOWNLOAD_REQUEST';
export const GL_ENTRY_CSV_DOWNLOAD_SUCCESS = 'GL_ENTRY_CSV_DOWNLOAD_SUCCESS';
export const GL_ENTRY_CSV_DOWNLOAD_FAILURE = 'GL_ENTRY__CSV_DOWNLOAD_FAILURE';

// Bank Details
export const GET_BANKS_DETAILS = 'GET_BANKS_DETAILS';
export const GET_BANKS_DETAILS_SUCCESS = 'GET_BANKS_DETAILS_SUCCESS';
export const GET_BANKS_DETAILS_FAIL = 'GET_BANKS_DETAILS_FAIL';

// OnfidoIdentification
export const ONFIDO_SDK_TOKEN_REQUEST = 'ONFIDO_SDK_TOKEN_REQUEST';
export const ONFIDO_INITIATE_CHECK = 'ONFIDO_INITIATE_CHECK';

// Onfido
export const GET_ONFIDO_APPLICANTS = 'GET_ONFIDO_APPLICANTS';
export const GET_ONFIDO_APPLICANTS_SUCCESS = 'GET_ONFIDO_APPLICANTS_SUCCESS';
export const GET_ONFIDO_APPLICANTS_FAILURE = 'GET_ONFIDO_APPLICANTS_FAILURE';
export const SAVE_SELECTED_APPLICANT = 'SAVE_SELECTED_APPLICANT';
export const CREATE_ONFIDO_APPLICANT = 'CREATE_ONFIDO_APPLICANT';
export const CREATE_ONFIDO_APPLICANT_SUCCESS =
    'CREATE_ONFIDO_APPLICANT_SUCCESS';
export const CREATE_ONFIDO_APPLICANT_FAILURE =
    'CREATE_ONFIDO_APPLICANT_FAILURE';
export const GET_ONFIDO_APPLICANT_DETAILS = 'GET_ONFIDO_APPLICANT_DETAILS';
export const GET_ONFIDO_APPLICANT_DETAILS_SUCCESS =
    'GET_ONFIDO_APPLICANT_DETAILS_SUCCESS';
export const GET_ONFIDO_APPLICANT_DETAILS_FAILURE =
    'GET_ONFIDO_APPLICANT_DETAILS_FAILURE';
export const GET_AVAILABLE_COUNTRIES = 'GET_AVAILABLE_COUNTRIES';
export const GET_AVAILABLE_COUNTRIES_SUCCESS =
    'GET_AVAILABLE_COUNTRIES_SUCCESS';
export const GET_AVAILABLE_COUNTRIES_FAILURE =
    'GET_AVAILABLE_COUNTRIES_FAILURE';

// Compliance Management
export const COMPLIANCE_MANAGEMENT_DATA_REQUEST =
    'COMPLIANCE_MANAGEMENT_DATA_REQUEST';
export const COMPLIANCE_MANAGEMENT_DATA_SUCCESS =
    'COMPLIANCE_MANAGEMENT_DATA_SUCCESS';
export const COMPLIANCE_MANAGEMENT_DATA_FAILURE =
    'COMPLIANCE_MANAGEMENT_DATA_FAILURE';
export const COMPLIANCE_MANAGEMENT_HISTORY_REQUEST =
    'COMPLIANCE_MANAGEMENT_HISTORY_REQUEST';
export const COMPLIANCE_MANAGEMENT_HISTORY_SUCCESS =
    'COMPLIANCE_MANAGEMENT_HISTORY_SUCCESS';
export const COMPLIANCE_MANAGEMENT_HISTORY_FAILURE =
    'COMPLIANCE_MANAGEMENT_HISTORY_FAILURE';
export const COMPLIANCE_MANAGEMENT_UPDATE_FAILURE =
    'COMPLIANCE_MANAGEMENT_UPDATE_FAILURE';
export const COMPLIANCE_MANAGEMENT_CONFIGURATION =
    'COMPLIANCE_MANAGEMENT_CONFIGURATION';
export const COMPLIANCE_MANAGEMENT_CONFIGURATION_FAILURE =
    'COMPLIANCE_MANAGEMENT_CONFIGURATION_FAILURE';

export const COMPLIANCE_GET_REPORT = 'COMPLIANCE_GET_REPORT';
export const COMPLIANCE_GET_REPORT_SUCCESS = 'COMPLIANCE_GET_REPORT_SUCCESS';
export const COMPLIANCE_GET_REPORT_FAILURE = 'COMPLIANCE_GET_REPORT_FAILURE';

// Role Settings
export const ROLE_SETTINGS_GET_ROLES_REQUEST =
    'ROLE_SETTINGS_GET_ROLES_REQUEST';
export const ROLE_SETTINGS_GET_ROLES_SUCCESS =
    'ROLE_SETTINGS_GET_ROLES_SUCCESS';
export const ROLE_SETTINGS_GET_ROLES_FAILURE =
    'ROLE_SETTINGS_GET_ROLES_FAILURE';
export const ROLE_SETTINGS_UPDATE_ROLE_REQUEST =
    'ROLE_SETTINGS_UPDATE_ROLE_REQUEST';
export const ROLE_SETTINGS_UPDATE_ROLE_SUCCESS =
    'ROLE_SETTINGS_UPDATE_ROLE_SUCCESS';
export const ROLE_SETTINGS_UPDATE_ROLE_FAILURE =
    'ROLE_SETTINGS_UPDATE_ROLE_FAILURE';
export const ROLE_SETTINGS_CREATE_ROLE_REQUEST =
    'ROLE_SETTINGS_CREATE_ROLE_REQUEST';
export const ROLE_SETTINGS_CREATE_ROLE_SUCCESS =
    'ROLE_SETTINGS_CREATE_ROLE_SUCCESS';
export const ROLE_SETTINGS_CREATE_ROLE_FAILURE =
    'ROLE_SETTINGS_CREATE_ROLE_FAILURE';
export const ROLE_SETTINGS_CSV_REQUEST = 'ROLE_SETTINGS_CSV_REQUEST';
export const ROLE_SETTINGS_CSV_SUCCESS = 'ROLE_SETTINGS_CSV_SUCCESS';
export const ROLE_SETTINGS_CSV_FAILURE = 'ROLE_SETTINGS_CSV_FAILURE';

// Account Settings
export const GET_ACCOUNT_SETTINGS_DATA = 'GET_ACCOUNT_SETTINGS_DATA';
export const GET_ACCOUNT_SETTINGS_DATA_SUCCESS =
    'GET_ACCOUNT_SETTINGS_DATA_SUCCESS';
export const GET_ACCOUNT_SETTINGS_DATA_FAILURE =
    'GET_ACCOUNT_SETTINGS_DATA_FAILURE';
export const DOWNLOAD_ACCOUNT_SETTINGS_DATA_SUCCESS =
    'DOWNLOAD_ACCOUNT_SETTINGS_DATA_SUCCESS';
export const DOWNLOAD_ACCOUNT_SETTINGS_DATA = 'DOWNLOAD_ACCOUNT_SETTINGS_DATA';
export const DOWNLOAD_ACCOUNT_SETTINGS_DATA_FAILURE =
    'DOWNLOAD_ACCOUNT_SETTINGS_DATA_FAILURE';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
export const EDIT_ACCOUNT_FAILURE = 'EDIT_ACCOUNT_FAILURE';
export const CREATE_ACCOUNTS = 'CREATE_ACCOUNTS';
export const CREATE_ACCOUNTS_SUCCESS = 'CREATE_ACCOUNTS_SUCCESS';
export const CREATE_ACCOUNTS_FAILURE = 'CREATE_ACCOUNTS_FAILURE';

// User Settings
export const USER_SETTINGS_GET_USERS_REQUEST =
    'USER_SETTINGS_GET_USERS_REQUEST';
export const USER_SETTINGS_GET_USERS_SUCCESS =
    'USER_SETTINGS_GET_USERS_SUCCESS';
export const USER_SETTINGS_GET_USERS_FAILURE =
    'USER_SETTINGS_GET_USERS_FAILURE';
export const USER_SETTINGS_UPDATE_USER_REQUEST =
    'USER_SETTINGS_UPDATE_USER_REQUEST';
export const USER_SETTINGS_UPDATE_USER_SUCCESS =
    'USER_SETTINGS_UPDATE_USER_SUCCESS';
export const USER_SETTINGS_UPDATE_USER_FAILURE =
    'USER_SETTINGS_UPDATE_USER_FAILURE';
export const USER_SETTINGS_CREATE_USER_REQUEST =
    'USER_SETTINGS_CREATE_USER_REQUEST';
export const USER_SETTINGS_CREATE_USER_SUCCESS =
    'USER_SETTINGS_CREATE_USER_SUCCESS';
export const USER_SETTINGS_CREATE_USER_FAILURE =
    'USER_SETTINGS_CREATE_USER_FAILURE';
export const USER_SETTINGS_CSV_REQUEST = 'USER_SETTINGS_CSV_REQUEST';
export const USER_SETTINGS_CSV_SUCCESS = 'USER_SETTINGS_CSV_SUCCESS';
export const USER_SETTINGS_CSV_FAILURE = 'USER_SETTINGS_CSV_FAILURE';

// Currency Settings
export const EDIT_CURRENCY = 'EDIT_CURRENCY';
export const EDIT_CURRENCY_SUCCESS = 'EDIT_CURRENCY_SUCCESS';
export const EDIT_CURRENCY_FAILURE = 'EDIT_CURRENCY_FAILURE';
export const CREATE_CURRENCY = 'CREATE_CURRENCY';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';
export const CREATE_CURRENCY_FAILURE = 'CREATE_CURRENCY_FAILURE';
export const GET_CURRENCY_SETTINGS_DATA = 'GET_CURRENCY_SETTINGS_DATA';
export const GET_CURRENCY_SETTINGS_DATA_SUCCESS =
    'GET_CURRENCY_SETTINGS_DATA_SUCCESS';
export const GET_CURRENCY_SETTINGS_DATA_FAILURE =
    'GET_CURRENCY_SETTINGS_DATA_FAILURE';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';
export const GET_TIMEZONES_SUCCESS = 'GET_TIMEZONES_SUCCESS';
export const GET_TIMEZONES_FAILURE = 'GET_TIMEZONES_FAILURE';

// User Settings
export const CLIENT_SETTINGS_GET_CLIENTS_REQUEST =
    'CLIENT_SETTINGS_GET_CLIENTS_REQUEST';
export const CLIENT_SETTINGS_GET_CLIENTS_SUCCESS =
    'CLIENT_SETTINGS_GET_CLIENTS_SUCCESS';
export const CLIENT_SETTINGS_GET_CLIENTS_FAILURE =
    'CLIENT_SETTINGS_GET_CLIENTS_FAILURE';
export const CLIENT_SETTINGS_UPDATE_CLIENT_REQUEST =
    'CLIENT_SETTINGS_UPDATE_CLIENT_REQUEST';
export const CLIENT_SETTINGS_UPDATE_CLIENT_SUCCESS =
    'CLIENT_SETTINGS_UPDATE_CLIENT_SUCCESS';
export const CLIENT_SETTINGS_UPDATE_CLIENT_FAILURE =
    'CLIENT_SETTINGS_UPDATE_CLIENT_FAILURE';
export const CLIENT_SETTINGS_CREATE_CLIENT_REQUEST =
    'CLIENT_SETTINGS_CREATE_CLIENT_REQUEST';
export const CLIENT_SETTINGS_CREATE_CLIENT_SUCCESS =
    'CLIENT_SETTINGS_CREATE_CLIENT_SUCCESS';
export const CLIENT_SETTINGS_CREATE_CLIENT_FAILURE =
    'CLIENT_SETTINGS_CREATE_CLIENT_FAILURE';
export const CLIENT_SETTINGS_GET_PARTNER_REQUEST =
    'CLIENT_SETTINGS_GET_PARTNER_REQUEST';
export const CLIENT_SETTINGS_GET_PARTNER_SUCCESS =
    'CLIENT_SETTINGS_GET_PARTNER_SUCCESS';
export const CLIENT_SETTINGS_GET_PARTNER_FAILURE =
    'CLIENT_SETTINGS_GET_PARTNER_FAILURE';

export const CLIENT_SETTINGS_CSV_REQUEST = 'CLIENT_SETTINGS_CSV_REQUEST';
export const CLIENT_SETTINGS_CSV_SUCCESS = 'CLIENT_SETTINGS_CSV_SUCCESS';
export const CLIENT_SETTINGS_CSV_FAILURE = 'CLIENT_SETTINGS_CSV_FAILURE';

export const CLIENT_SETTINGS_CALLBACK_ACTION = 'CLIENT_SETTINGS_CALLBACK_ACTION';

export const DEFAULT_NON_BPSP_PRICING_REQUEST =
    'DEFAULT_NON_BPSP_PRICING_REQUEST';
export const DEFAULT_NON_BPSP_PRICING_SUCCESS =
    'DEFAULT_NON_BPSP_PRICING_SUCCESS';
export const DEFAULT_NON_BPSP_PRICING_FAILURE =
    'DEFAULT_NON_BPSP_PRICING_FAILURE';
export const PAYMENT_ALERT_DATA_REQUEST = 
    'PAYMENT_ALERT_DATA_REQUEST';
export const PAYMENT_ALERT_DATA_SUCCESS = 
    'PAYMENT_ALERT_DATA_SUCCESS';
export const PAYMENT_ALERT_DATA_FAILURE =
    'PAYMENT_ALERT_DATA_FAILURE';
export const PAYMENT_ALERT_USER_SUCCESS =
    'PAYMENT_ALERT_USER_SUCCESS';
export const PAYMENT_ALERT_USER_REQUEST =
    'PAYMENT_ALERT_USER_REQUEST';
export const PAYMENT_ALERT_CSV_DOWNLOAD_SUCCESS = 'PAYMENT_ALERT_CSV_DOWNLOAD_SUCCESS';
export const PAYMENT_ALERT_CSV_DOWNLOAD_FAILURE = 'PAYMENT_ALERT_CSV_DOWNLOAD_FAILURE';
export const PAYMENT_ALERT_CSV_DOWNLOAD_REQUEST = 'PAYMENT_ALERT_CSV_DOWNLOAD_REQUEST';
export const PAYMENT_ALERT_EMAIL_REQUEST = 'PAYMENT_ALERT_EMAIL_REQUEST';
export const PAYMENT_ALERT_EMAIL_SUCCESS = 'PAYMENT_ALERT_EMAIL_SUCCESS';
export const PAYMENT_ALERT_UPDATE_REQUEST = 'PAYMENT_ALERT_UPDATE_REQUEST';
export const PAYMENT_ALERT_UPDATE_SUCCESS = 'PAYMENT_ALERT_UPDATE_SUCCESS';
export const PAYMENT_ALERT_CLIENT_SUCCESS = 'PAYMENT_ALERT_CLIENT_SUCCESS';
export const PAYMENT_ALERT_CLIENT_REQUEST = 'PAYMENT_ALERT_CLIENT_REQUEST';

export const GET_COMMENTS_REQ = 'GET_COMMENTS_REQ';
export const GET_COMMENTS_RES = 'GET_COMMENTS_RES';
export const GET_COMMENTS_ERR = 'GET_COMMENTS_ERR';
export const CREATE_COMMENTS_REQ = 'CREATE_COMMENTS_REQ';
export const CREATE_COMMENTS_RES = 'CREATE_COMMENTS_RES';
export const CREATE_COMMENTS_ERR = 'CREATE_COMMENTS_ERR';

// Rejected ICC View
export const REJECTED_ICC_DATA_REQUEST = 'REJECTED_ICC_DATA_REQUEST';
export const REJECTED_ICC_DATA_SUCCESS = 'REJECTED_ICC_DATA_SUCCESS';
export const REJECTED_ICC_CHANGE_STATUS_REQUEST = 'REJECTED_ICC_CHANGE_STATUS_REQUEST';
export const REJECTED_ICC_CHANGE_STATUS_SUCCESS = 'REJECTED_ICC_CHANGE_STATUS_SUCCESS';

export const GL_ENTRY_BATCH_ACTION = 'GL_ENTRY_BATCH_ACTION';
export const GENERAL_CONFIG = 'GENERAL_CONFIG';

// Inward Credit Confirmation
export const INWARD_CREDIT_CONFIRMATION_FX_DATA_REQUEST = 'INWARD_CREDIT_CONFIRMATION_FX_DATA_REQUEST';
export const INWARD_CREDIT_CONFIRMATION_DATA_SUCCESS = 'INWARD_CREDIT_CONFIRMATION_DATA_SUCCESS';
export const INWARD_CREDIT_CONFIRMATION_DATA_FAILURE = 'INWARD_CREDIT_CONFIRMATION_DATA_FAILURE';
export const INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_REQUEST = 'INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_REQUEST';
export const INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_SUCCESS = 'INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_SUCCESS';
export const INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_FAILURE = 'INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_FAILURE';
export const GET_CLIENT_ACCOUNT_NUMBER_DATA_SUCCESS = 'GET_CLIENT_ACCOUNT_NUMBER_DATA_SUCCESS';
export const APPROVE_FAILED_ICC = 'APPROVE_FAILED_ICC_SUCCESS';
export const REJECT_FAILED_ICC = 'REJECT_FAILED_ICC_SUCCESS';
export const CREATE_PAYIN_ICC = 'CREATE_PAYIN_ICC';

export const MANUAL_TRANSACTION_REQUEST = 'MANUAL_TRANSACTION_REQUEST';
export const MANUAL_TRANSACTION_SUCCESS= 'MANUAL_TRANSACTION_SUCCESS';
export const MANUAL_TRANSACTION_FAILURE = 'MANUAL_TRANSACTION_FAILURE';

// Compliance RFI Response 
export const COMPLIANCE_RFI_RESPONSE_SUBMIT_REQUEST='COMPLIANCE_RFI_RESPONSE_SUBMIT_REQUEST';
export const COMPLIANCE_RFI_RESPONSE_SUBMIT_SUCCESS='COMPLIANCE_RFI_RESPONSE_SUBMIT_SUCCESS';
export const COMPLIANCE_RFI_RESPONSE_SUBMIT_ERROR='COMPLIANCE_RFI_RESPONSE_SUBMIT_ERROR';
