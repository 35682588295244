import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { reducerConfig } from './reducerConfig';
import { history } from './history';
import { USER_LOGOUT } from '../actions/types';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const persistConfig = {
    key: 'operations-dashboard',
    storage: localforage,
    whitelist: ['constants', 'header', 'sidebar', 'userInfo'],
};

const appReducer = combineReducers({
    router: connectRouter(history),
    ...reducerConfig,
});
const createRootReducer = (state: any, action: any) => {
    if (action.type === USER_LOGOUT) {
        localforage.removeItem(`persist:${persistConfig.key}`);
        state = undefined;
    }
    return appReducer(state, action);
}

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
);